var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('g-table',{ref:"employeeAdvancePayment-table",attrs:{"items":_vm.itemsProvider,"columns":_vm.tableColumns,"noAction":true,"perPage":"25","totalRows":_vm.totalRows,"createButton":{ visiable: true, permission: 'addAdvancePayment' },"searchInput":{ visiable: true }},on:{"filtered":_vm.onFiltered,"on-create":function (v) {
        _vm.$router.push({ name: 'employeeAdvancePayment-new' });
      }},scopedSlots:_vm._u([{key:"actions",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"text-nowrap"},[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(_vm.$t('edit')),expression:"$t('edit')",modifiers:{"hover":true,"top":true}},{name:"permission",rawName:"v-permission",value:('editAdvancePayment'),expression:"'editAdvancePayment'"}],staticClass:"btn-icon",attrs:{"data-action-type":"edit","variant":"flat-secondary","size":"sm"},on:{"click":function($event){return _vm.edit(item)}}},[_c('feather-icon',{staticClass:"clickable",attrs:{"icon":"EditIcon"}})],1),_c('b-button',{directives:[{name:"permission",rawName:"v-permission",value:('viewVacationRequest'),expression:"'viewVacationRequest'"},{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(item.attachmentUrl !== null
            ? _vm.$t('hasAttachMents')
            : _vm.$t('hasNotAttachMents')
          ),expression:"item.attachmentUrl !== null\n            ? $t('hasAttachMents')\n            : $t('hasNotAttachMents')\n          ",modifiers:{"hover":true,"top":true}}],staticClass:"btn-icon",attrs:{"data-action-type":"download","variant":"flat-warning","size":"sm"},on:{"click":function (v) {
            if (item.attachmentUrl !== null) {
              _vm.downloadItem(item);
            }
          }}},[_c('feather-icon',{attrs:{"icon":item.attachmentUrl !== null ? 'FolderPlusIcon' : 'FolderIcon',"stroke":"orange"}})],1),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(_vm.$t('print')),expression:"$t('print')",modifiers:{"hover":true,"top":true}},{name:"permission",rawName:"v-permission",value:('viewAdvancePayment'),expression:"'viewAdvancePayment'"}],staticClass:"btn-icon",attrs:{"variant":"flat-primary","size":"sm"},on:{"click":function (v) {
              _vm.print(item);
            }}},[_c('feather-icon',{staticClass:"clickable",attrs:{"icon":"PrinterIcon","stroke":"green"}})],1),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(_vm.$t('delete')),expression:"$t('delete')",modifiers:{"hover":true,"top":true}},{name:"permission",rawName:"v-permission",value:('deleteAdvancePayment'),expression:"'deleteAdvancePayment'"}],staticClass:"btn-icon",attrs:{"data-action-type":"delete","variant":"flat-danger","size":"sm"},on:{"click":function($event){return _vm.remove(item)}}},[_c('feather-icon',{staticClass:"danger",attrs:{"icon":"TrashIcon","stroke":"red"}})],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }